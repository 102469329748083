.chat {
  --ion-background-color: #FFF;
}

.chat-bubble-row {
  display: flex;
}

.message {
  border-radius: 4px;
  margin-bottom: 0.4rem;
  padding: 0.4rem;
  color: #fff;
  white-space: pre-wrap;
}

.add-message-form {
  
}