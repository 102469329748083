.chat-bubble-row {
  display: flex;
  flex-direction: row;
}

.right.chat-bubble-row  {
  flex-direction: row-reverse;
}

.chat-bubble-col {
  margin-bottom: 0.4rem;
}

.message {
  border-radius: 4px;
  padding: 0.4rem;
  white-space: pre-wrap;
  background-color: #cecece;
  color: #333;
}

.right .message {
  background-color: #1ab1ad;
  color: #fff;
}

.time {
  font-size: smaller;
  color: #555;
}

.right .time {
  text-align: right;
}
