.welcome-card img {
  max-height: 35vh;
  overflow: hidden;
}

.home ion-content {
  --background: url('/assets/images/hero.jpg') no-repeat center/cover fixed;
}

.home ion-grid, .home ion-row { 
  height: 100%;
}

ion-card-title {
  font-size: 1.6rem;
}

ion-img {
  padding: 20px 80px;
}

.home .firebaseui-container {
  max-width: 100%;
}
.home .firebaseui-idp-button {
  max-width: 100%;
  /* background-color: #fff !important; */
  padding: 8px;
}

.firebaseui-idp-button .firebaseui-idp-icon {
  height:24px;
  width: 24px;
}

/* .firebaseui-idp-button>.firebaseui-idp-text {
  color: #757575;
} */

.firebaseui-provider-sign-in-footer>.firebaseui-tos, a.firebaseui-link {
  color: #fff;
  font-size: small;
}

ion-text {
  font-size: small;
}

.card-footer {
  background-color: #fff;
  color: #757575;
}

ion-router-link {
  font-weight: 700;
}