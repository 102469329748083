.add-message-form {
  border-top: 1px solid #cecece
}

.add-message-form div {
  padding-right: 0px;
}

.add-message-form button {
  height: 100%;
  background-color: #cecece;
  font-size: x-large;
  padding: 0 1em;
}