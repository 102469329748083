ion-menu {
  font-weight: 700;
}

.menu-logo {
  width: 50%;
  padding: 0 10px;
}

.ion-no-border > ion-toolbar { 
  border: none;
}

#menu ion-list {
  background-color: var(--ion-primary-color);
}
/* #menu .item:hover {
  --ion-color: #1ab1ad;
  --ion-background-color: #2afff8;
} */