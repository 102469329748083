#dashboard {
  max-width: 100%;
}

img.react-gravatar {
  width: auto;
}

ion-menu {
  --side-width: 150px;
}
